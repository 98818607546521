<template>
  <div class="registration_wrapper">
    <app-navbar />
    <app-wrapper>
      <div class="registration_form">
        <ui-card
          v-loading="loading"
          v-if="registrationLink && registrationLink.company"
          :heading="$t('registration.register_user')"
          class="w-full max-w-lg"
        >
          <ui-form
            :submit-text="$t('registration.register_user')"
            @submit="handleSubmit"
            @cancel="handleCancel"
            :submitDisabled="submitButtonDisabled"
          >
          <template v-if="emailIsNotRegistered === false && emailExistsAndUserIsNotRegistered === false" #buttons>
            <div class="flex w-full justify-end">
              <ui-link type="primary" @click="resetPasswordClicked">{{$t('auth.reset_password')}}</ui-link>
            </div>
          </template>
            <ui-form-field :label="$t('fields.email')" rules="required">
              <el-input
                type="email"
                v-model="email"
                @change="validateEmail"
              ></el-input>
            </ui-form-field>

            <ui-form-field :label="$tc('models.company', 1)">
              <el-input
                type="text"
                v-if="registrationLink"
                v-model="registrationLink.company.name"
                disabled
              ></el-input>
            </ui-form-field>

            <ui-form-field :label="$tc('models.building', 1)">
              <el-input
                type="text"
                v-if="registrationLink"
                v-model="registrationLink.building.name"
                disabled
              ></el-input>
            </ui-form-field>

            <ui-form-field
              v-if="registrationLink && registrationLink.department"
              :label="$tc('models.department', 1)"
            >
              <el-input
                type="text"
                v-model="registrationLink.department.name"
                disabled
              ></el-input>
            </ui-form-field>

            <ui-form-field
              v-if="registrationLink && registrationLink.selectableDepartments"
              :label="$tc('models.department', 1)"
              rules="required"
            >
              <el-select
                v-model="registrationLink.assignedDepartmentId"
                placeholder="Select department"
                v-if="registrationLink.selectableDepartments"
                style="display: initial"
                clearable
              >
                <el-option
                  v-for="item in registrationLink.selectableDepartments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </ui-form-field>

            <ui-form-field :label="$t('fields.password')" rules="required">
              <el-input type="password" v-model="password"></el-input>
            </ui-form-field>

            <ui-form-field
              :label="$t('fields.password_confirmation')"
              :rules="emailIsNotRegistered || emailExistsAndUserIsNotRegistered ? 'required' : ''"
              v-if="emailIsNotRegistered || emailExistsAndUserIsNotRegistered"
            >
              <el-input
                type="password"
                v-model="confirmation"
              ></el-input>
            </ui-form-field>

            <div v-if="emailIsNotRegistered === false && emailExistsAndUserIsNotRegistered === false" class="mb-4">
              <div>{{ $t('links.email_in_use_reset_password') }}</div>
              <div>{{ $t('links.after_password_is_set') }}</div>
            </div>
            <template
              v-else-if="emailIsNotRegistered === true || emailExistsAndUserIsNotRegistered === true"
            >
              <ui-form-feedback>
                <ul class="mb-8">
                  <li class="flex">
                    <svg
                      v-if="isEmailValid"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!isEmailValid"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>
                      {{ $t("validation.email_invalid") }}
                    </div>
                  </li>
                  <li class="flex">
                    <svg
                      v-if="hasLength"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasLength"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>
                      {{ $t("validation.minimum_characters", { min: 6 }) }}
                    </div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasCapital"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasCapital"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_uppercase_letter") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasSmall"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasSmall"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_lowercase_letter") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasNumber"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasNumber"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_number") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="hasSpecial"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!hasSpecial"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.has_special_character") }}</div>
                  </li>

                  <li class="flex">
                    <svg
                      v-if="isConfirmed"
                      class="icon valid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M8.294 16.998c-.435 0-.847-.203-1.111-.553L3.61 11.724a1.392 1.392 0 0 1 .27-1.951 1.392 1.392 0 0 1 1.953.27l2.351 3.104 5.911-9.492a1.396 1.396 0 0 1 1.921-.445c.653.406.854 1.266.446 1.92L9.478 16.34a1.39 1.39 0 0 1-1.12.656c-.022.002-.042.002-.064.002z"
                      ></path>
                    </svg>
                    <svg
                      v-if="!isConfirmed"
                      class="icon invalid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                      ></path>
                    </svg>
                    <div>{{ $t("validation.matches_confirmation") }}</div>
                  </li>
                </ul>
              </ui-form-feedback>
            </template>
          </ui-form>
        </ui-card>
      </div>
    </app-wrapper>
  </div>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import UiForm from "@/components/ui/UiForm";
import UiLink from "@/components/ui/UiLink";
import UiFormField from "@/components/ui/UiFormField";
import UiFormFeedback from "@/components/ui/UiFormFeedback";
import { createUser } from "@/app/auth/api";
import AppNavbar from "@/components/app/AppNavbarGuest";
import AppWrapper from "@/components/app/AppWrapperGuest";
import {
  getCompany,
  deleteCompany,
  createRegistrationLink,
  getRegistrationLinkByReference,
  getRegistrationLinkByReferenceExtended,
  getActiveCompanyPermissions,
  associateUserToReference,
} from "@/app/companies/api";
import { emailExists } from "../api/";

export default {
  components: {
    AppNavbar,
    UiCard,
    UiForm,
    UiFormField,
    UiFormFeedback,
    AppWrapper,
    UiLink
  },

  data() {
    return {
      email: "",
      password: "",
      confirmation: "",
      loading: false,
      guid: null,
      registrationLink: null,
      emailIsNotRegistered: null,
      emailExistsAndUserIsNotRegistered: null,
    };
  },

  computed: {
    hasLength() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? this.password.length >= 6 : true
    },
    hasSpecial() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? !!this.password.match(/[^\w^\d]/) : true;
    },
    hasSmall() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? !!this.password.match(/[a-å]/) : true;
    },
    hasCapital() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? !!this.password.match(/[A-Z]/) : true;
    },
    hasNumber() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? !!this.password.match(/[\d]/) : true;
    },
    isConfirmed() {
      return this.emailIsNotRegistered || this.emailExistsAndUserIsNotRegistered ? this.password.length > 0 && this.password === this.confirmation : true;
    },

    isEmailValid() {
      return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        this.email
      );
    },
    submitButtonDisabled() {
      return (
        !this.hasLength ||
        !this.hasSmall ||
        !this.hasCapital ||
        !this.hasNumber ||
        !this.hasSpecial ||
        !this.isConfirmed
      );
    },
  },

  mounted() {
    this.guid = this.$route.params.guid;
    this.setupReference();
  },

  methods: {
    async setupReference() {
      if (this.guid) {
        try {
          getRegistrationLinkByReferenceExtended(this.guid)
            .then((body) => (this.registrationLink = body))
            .then(() => {
              if (!this.registrationLink) {
                this.handleCancel();
              }
            })
            .catch(() => this.handleCancel());
        } catch (e) {}
      } else {
        this.handleCancel();
        return;
      }
    },

    async handleSubmit() {
      if (
        !this.hasLength ||
        !this.hasSpecial ||
        !this.hasSmall ||
        !this.hasCapital ||
        !this.hasNumber ||
        !this.isConfirmed
      )
        return;

      this.loading = true;

      try {
        if (this.emailIsNotRegistered && !this.emailExistsAndUserIsNotRegistered) {
          await createUser(this.email, this.password);
        }

        await associateUserToReference(
          this.guid,
          this.email,
          this.registrationLink.assignedDepartmentId,
          this.password
        );

        this.$message({
          message: this.$t("registration.user_created"),
          type: "success",
        });
        this.$router.push({ name: "my-courses-index" });
      } catch (error) {
        if (error.message.includes("401")) {
          this.$message({
            message: this.$t("auth.password_incorrect"),
            type: "error",
          });
        } else {
          this.$message({
            message: this.$t("registration.not_found"),
            type: "error",
          });
          this.$router.push({ name: "login" });
        }
      }
      this.loading = false;
    },

    handleCancel() {
      this.$message({
        message: this.$t("registration.not_found"),
        type: "error",
      });
      this.$router.push({ name: "login" });
    },

    validateEmail(e) {
      this.emailIsNotRegistered = false;
      emailExists(this.email)
        .then(res => {
          this.emailIsNotRegistered = !res.userExists && !res.hasCompletedRegistration;
          this.emailExistsAndUserIsNotRegistered = res.userExists && !res.hasCompletedRegistration
        });
    },

    resetPasswordClicked() {
      this.$router.push({ name: "reset-password" });
    },
  },
};
</script>

<style>
.registration_wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.registration_form {
  margin: 5em auto 0 auto;
  width: 30em;
}

.flex {
  display: flex;
}

.icon {
  @apply w-4 h-4 mr-4 block text-gray-500 fill-current;
}

.valid {
  fill: green;
}

.invalid {
  fill: red;
}

.set_password_form {
  margin-top: 5em;
  width: 30em;
}
</style>
