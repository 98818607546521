import api, { http } from '@/services/api';

export const setPassword = async payload => {
  const result = await api.post('unprotected/setpassword', payload);
  return result.data;
};

export const resetPassword = async email => {
  const result = await api.post('unprotected/resetpassword', email);
  return result.data;
};

export const resendEmailConfirmation = async payload => {
  const result = await api.post('unprotected/resendsetpasswordtoken', payload);
  return result.data;
};

export const createUser = async (email, password) => {
  const result = await http.post(`${process.env.VUE_APP_OIDC_AUTHORITY}account/register`, {}, { params: { email, password } });
  return result.data;
};

export const validateSetPasswordToken = async (userId, token, isResetRequest) => {
  try {
    const result = await api.post('unprotected/set-password/tokenvalidation', { userId, token, isResetRequest });
    return result.status === 200;
  } catch (e) {
    console.error(e)
    return false;
  }
}

