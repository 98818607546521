<template>
  <app-header-dropdown
      :heading="$t('language.language')"
      :items="items"
      :use-colored-flags="useColoredFlags"
      class="app_header_dropdown"
  >
  </app-header-dropdown>
</template>

<script>
import {SupportedLanguages} from '@/i18n/lang';
import {map} from 'ramda';
import AppHeaderDropdown from '@/components/app/AppHeaderDropdown';
import {translate} from "@/i18n/Translator";

export default {
  name: 'LanguageSwitcher',

  props: {
    /** Denotes if colored version of Country flags should be used */
    useColoredFlags: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      languages: SupportedLanguages
    };
  },

  components: {
    AppHeaderDropdown
  },

  computed: {
    items() {
      return map(lang => {
        return {
          label: this.$t(`language.${lang.label}`),
          method: () => {
            this.changeLang(lang.value);
          }
        };
      }, this.languages);
    },
  },

  methods: {
    changeLang(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
        this.$router.push({params: {lang: lang}});
      }
    },
    translateWord(word) {
      return translate(word, this.$i18n.locale)
    }
  }
};
</script>

<style>
.app_header_dropdown {
  display: flex;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 1023px) {
  .app_header_dropdown {
    display: initial !important;
  }
}
</style>
